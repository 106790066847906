<!-- 下单管理  - 用气报修 -->
<template>
  <XkContainer>
    <el-form :inline="true" class="add-form-box add-form-style mt-10" label-width="80px" label-position="left">
      <el-col :span="8">
        <el-form-item label="报修人">
          <el-input maxLength="100" placeholder="报修人" v-model="upData.CusName">
            <template slot="suffix">
              <el-button type="primary" size="mini" @click="getCustomer">查询</el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="报修电话">
          <el-input maxLength="100" placeholder="报修电话" v-model="upData.CusPhone"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="所属站点">
          <DropDownStationList :val.sync="upData.BelongId" placeholder="请选择站点"></DropDownStationList>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="报修地址">
          <el-input placeholder="报修地址" v-model="upData.CusAddress" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="报修内容">
          <el-input maxLength="100" placeholder="报修内容" v-model="upData.Content" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="备注">
          <el-input maxLength="100" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请输入内容" v-model="upData.Remark" clearable />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="维修站点">
          <DropDownStationList :val.sync="upData.RepairStationId" placeholder="维修站点"></DropDownStationList>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="处理人员">
          <XkDropDown
            :disabled="upData.RepairStationId ? false : true"
            :url="'getOperatorList'"
            :urlParams="stationUrlParams"
            :val.sync="upData.OperatorId"
            placeholder="处理人员"
            lable="Name"
            value="Id"
          ></XkDropDown>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item align="right">
          <el-button type="primary" :loading="btnLogin" round @click="addInfo">保存</el-button>
        </el-form-item>
      </el-col>
    </el-form>
    <!-- 查询报修人弹窗 -->
    <!-- 查询客户弹窗 -->
    <XkMaxDialog :dialogVisible.sync="alertCustome" :title="`查询客户`" :getIsfull="false" :titleVisible.sync="alertCustome">
      <template #content>
        <PlaceAnOrderAddCustomer
          :phone="upData.CusPhone"
          :name="upData.CusName"
          @getData="getChildrenData"
          :getCustomerType="getCustomerType"
          @closeInfo="alertCustome = false"
        ></PlaceAnOrderAddCustomer>
      </template>
    </XkMaxDialog>
  </XkContainer>
</template>
<script>
import DropDownStationList from '@/components/DropDown/StationList';
// import PlaceAnOrderAddCustomer from './placeAnOrderAddCustomer.vue'
import PlaceAnOrderAddCustomer from '@/compPages/searchUser/index.vue'; //查询客户组件
import { postRepairInfoAdd } from '@/api/repairInfo';
import { mapGetters } from 'vuex';
export default {
  name: '',
  data() {
    return {
      btnLogin: false, //加载下单按钮
      upData: {
        BelongId: '',
        Phone: '',
        Remark: '',
        CusAddress: '',
        Content: '',
        OperatorId: '',
        RepairStationId: '',
      },
      alertCustome: false, //查询报修人弹窗
      getCustomerType: '',
    };
  },
  components: {
    PlaceAnOrderAddCustomer, //报修弹窗
    DropDownStationList, //站点
  },
  mounted() {},
  created() {},
  computed: {
    ...mapGetters({
      gettOrderInfoData: 'gettOrderInfoData',
    }),
    stationUrlParams: function() {
      return { stationId: this.upData.RepairStationId };
    },
  },
  watch: {
    gettOrderInfoData: {
      handler(n, o) {
        this.upData.CusName = this.gettOrderInfoData.Name; //报修人
        this.upData.CusId = this.gettOrderInfoData.Id; //用户id
        this.upData.CusPhone = this.gettOrderInfoData.Phone; //报修电话
        if (!this.gettOrderInfoData.GasAddress) {
          this.upData.CusAddress = this.gettOrderInfoData.IdentityAddress || ''; //身份证地址
        } else {
          this.upData.CusAddress = this.gettOrderInfoData.GasAddress || ''; //报修地址
        }
        this.upData.BelongId = this.gettOrderInfoData.StationId; //所属站点
        this.upData = JSON.parse(JSON.stringify(this.upData));
      },
      deep: true,
      // immediate: true,
    },
  },
  methods: {
    // 查询客户弹窗
    getCustomer() {
      this.getCustomerType = 'Manual'; //手动
      this.alertCustome = true;
    },
    getChildrenData(val) {
      console.log(val);
      this.upData.CusId = val.Id;
      this.upData.Number = val.Number;
      this.upData.CusName = val.Contact;
      this.upData.CusPhone = val.Phone;
      this.upData.CusAddress = val.GasAddress;
      this.upData.BelongId = val.StationId;
      this.upData.StationName = val.StationName;
      this.upData.CusName = val.Name;
      this.upData = JSON.parse(JSON.stringify(this.upData));
      this.alertCustome = false;
    },
    async addInfo(val) {
      if (Object.keys(this.gettOrderInfoData).length == 0 || !this.gettOrderInfoData.Id) {
        this.$message.error('请先保存用户信息!');
        return;
      }
      this.btnLogin = true;
      let res = await postRepairInfoAdd(this.upData);
      if (res.Success) {
        this.btnLogin = false;
        this.$message({
          message: res.Msg || '成功',
          type: 'success',
        });
        this.$store.dispatch('user/getOrderInfoData', {});
        //关闭下单页面
        setTimeout(() => {
          window.close();
        }, 1000);
      } else {
        this.$message.error(res.Msg || '失败');
      }
    },
  },
};
</script>
