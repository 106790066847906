/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-24 09:08:09
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-08-23 17:55:15
 * @FilePath: \src\utils\getDate.js
 */
function timeStampToFormatedDateStr(month, daysAgo) { 
    var time = new Date();
    
    // 如果传入了 daysAgo，则减去相应的天数
    if (daysAgo) {
        time.setDate(time.getDate() - daysAgo);
    }

    var y = time.getFullYear();
    var m;
    var adjustedYear = y;

    if (month) {
        m = time.getMonth() + 1 - month;
        // 处理减去月份导致年份倒退的情况
        if (m <= 0) {
            adjustedYear--;
            m = 12 + m;
        }
    } else {
        m = time.getMonth() + 1;
    }

    // 控制天数不超过当月实际天数
    var lastDayOfMonth = new Date(adjustedYear, m, 0).getDate();
    var d = Math.min(time.getDate(), lastDayOfMonth);

    // 格式化日期部分
    return adjustedYear + '-' + add0(m) + '-' + add0(d);
}


function add0(value) {
    return (value < 10) ? '0' + value : value;
}
// 日期过滤
function rTime(date) {
    var json_date = new Date(date).toJSON();
    return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
}
function ghFilter(date){
    return date.replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').split('.')[0]
}
export default{
    timeStampToFormatedDateStr,rTime,ghFilter
}

