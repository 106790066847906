<!--
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2023-08-30 10:00:18
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-05-30 10:34:05
 * @FilePath: \src\compPages\Password\index.vue
 
-->
<template>
  <div class="el-dialog-main">
    <el-form :inline="true" class="add-form-box" :label-position="'right'" ref="upData" :model="upData" :rules="rules" label-width="100px">
      <el-form-item label="旧密码" prop="PrePwd">
        <el-input maxLength="100" width="80%" type="password" placeholder="请输入密码" v-model="upData.PrePwd"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="Pwd">
        <el-input maxLength="100" width="80%" type="password" placeholder="请输入密码" v-model="upData.Pwd"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="ConfirmPwd">
        <el-input maxLength="100" width="80%" type="password" placeholder="确认新密码" v-model="upData.ConfirmPwd"></el-input>
      </el-form-item>
    </el-form>
    <div class="form-btn">
      <el-button type="primary" round @click="addInfo('upData')">保存</el-button>
      <el-button round @click="close">取消</el-button>
    </div>
  </div>
</template>
<script>
import { updatePwd } from '@/api/user';
export default {
  name: 'XkPassword',
  data() {
    return {
      btnLogin: false,
      upData: {
        PrePwd: '',
        Pwd: '',
        ConfirmPwd: '',
      },
      rules: {
        PrePwd: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        Pwd: [{ required: true, message: '确认新密码', trigger: 'blur' }],
        ConfirmPwd: [{ required: true, message: '确认新密码', trigger: 'blur' }],
      },
    };
  },
  components: {},
  created() {},
  methods: {
    addInfo() {
      this.$refs['upData'].validate((valid) => {
        this.$debounce(async () => {
          if (valid) {
            let res = await updatePwd(this.upData);
            if (res.Success) {
              this.btnLogin = false; //加载下单按钮
              this.$message({
                message: '保存成功',
                type: 'success',
              });
              this.$emit('closeInfo');
            }
          }
        });
      });
    },
    close() {
      this.$emit('closeInfo');
    },
  },
};
</script>
<style scoped lang="scss">
.el-row {
  height: 80px;
  margin-top: 20px;
  background: rgba($color: #000000, $alpha: 0.01);
  border-top: 1px solid #eaedf7;
  padding: 20px 20px 0 20px;
  .el-button {
    float: right;
    margin-left: 10px;
  }
}
</style>
