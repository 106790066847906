<!--
一般提交
<my-confirm-button @click="syncApi" :icon="'el-icon-refresh'" style="margin:0px;">
    <template #content>
      <p>确定要同步Api吗？</p>
    </template>
    同步Api
</my-confirm-button>

行删除
<my-confirm-button type="delete" @click="handleDel($index, row)" :loading="row._loading"/>

表单验证
<my-confirm-button type="submit" @click="editSubmit" :validate="editFormvalidate" :loading="editLoading"/>

设置位置
<my-confirm-button @click="submit" :validate="editFormvalidate" :placement="'top-start'" :loading="editLoading">
    <template #content>
      <p>确定要更新基本信息吗？</p>
    </template>
    更新基本信息
</my-confirm-button>
-->
<template>
  <span class="opt-item" style="display: inline-block;">
    <el-dialog title="提示" :visible.sync="disabledDialog" append-to-body width="500px" top="25vh">
      <slot name="content">
        <h1 class="deleteText"><i class="orange el-icon-question"></i>确定要{{ buttonText }}该数据吗？{{ roleText }}</h1>
      </slot>
      <template slot="footer">
        <el-button round @click="disabledDialog = false">取消</el-button>
        <el-button type="primary" round @click="sure(false)">确定</el-button>
      </template>
    </el-dialog>
    <template v-if="btnLink">
      <el-link :type="type" @click="valid">{{ buttonText }}</el-link>
    </template>
    <template v-else>
      <el-button v-if="!btnType" :disabled="disabled" :type="type" :size="size" @click="valid">{{ buttonText }}</el-button>
      <el-tag v-else :type="type" effect="dark" size="mini" @click="valid">{{ buttonText }}</el-tag>
    </template>
  </span>
</template>

<script>
export default {
  name: 'MyConfirmButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'top-end',
    },
    btnLink: {
      type: Boolean,
      default: false,
    },
    btnType: {
      type: Boolean,
      default: true, // true: link false: button
    },
    roleText: String,
    btnWord: String, // 按钮文字
    // 按钮的type
    type: {
      type: String,
      default: 'danger', // 提交 primary 删除 delete danger
    },
    validate: {
      type: Function,
      default: null,
    },
    validateData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    size: {
      type: String,
      default: 'mini',
    },
  },
  data() {
    return {
      visible: false,
      disabledDialog: false,
      buttonText: '',
      style: '',
    };
  },
  computed: {},
  watch: {
    type: {
      handler: function() {
        this.buttonText = this.buttonTextValue();
      },
      immediate: true,
    },
  },
  mounted: function() {},
  methods: {
    //类型
    buttonTextValue() {
      switch (this.type) {
        case 'danger':
          return this.btnWord || '删除';
        case 'warning':
          return this.btnWord || '禁用';
        case 'submit':
          return this.btnWord || '提交';
        case 'success':
          return this.btnWord || '启用';
        default:
          return this.btnWord;
      }
    },
    hide() {
      if (this.validate) {
        this.disabledDialog = true;
      }
    },
    //
    valid() {
      //
      if (this.validate) {
        const isValid = this.validate(this.validateData);
        console.log('isvalid', isValid, this.validateData);
        if (isValid) {
          this.disabledDialog = true;
        }
      } else {
        this.disabledDialog = true;
      }
    },
    sure() {
      this.disabledDialog = false;
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  border-top: 0px solid #e6e6e6;
  text-align: center;
  .el-button {
    margin: 0 12px;
    &.is-round {
      padding: 12px 35px;
    }
  }
}

.deleteText {
  font-size: 16px;
  font-weight: normal;
}
.orange {
  color: #fe9400;
  font-size: 18px;
  margin-right: 5px;
}
.el-button--primary .el-link.el-link--danger {
  color: #fff;
}
</style>
