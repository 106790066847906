/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-09 10:35:56
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-04-07 16:19:28
 * @FilePath: \src\store\getters.js
 */
const getters = {
    menus:state=>state.user.menus,
    planOrderPermission:state=>state.user.planOrderPermission,
    tableThList:state=>state.user.tableThList,
    filterList:state=>state.user.filterList,
    token:state=>state.user.token,
    hostConfig:state=>JSON.parse(state.user.hostConfig),
    pageButtons:state=>state.user.pageButtons,
    gettOrderInfoData:state=>state.user.orderInfoData,
    addOrderfromData:state=>state.user.addOrderfromData,
    changeMenusData:state=>state.user.changeMenusData,
}
export default getters