<!--
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-09 10:35:56
 * @LastEditors: MasterGehong
 * @LastEditTime: 2023-08-02 13:33:46
 * @FilePath: \呼叫中心\src\components\XkContainer.vue
-->
<!--
 * 容器组件
 * 使用说明
 -->
<template>
  <div class="flex-col" :style="{ width: width }">
    <div class="flex-content">
      <div class="header" v-if="$slots.header" ref="headerBox">
        <slot name="header" />
      </div>
      <div class="search" v-if="$slots.search" ref="searchBox">
        <slot name="search" />
      </div>
      <div class="">
        <slot name="add" />
      </div>
      <div class="flex-wrapper">
        <slot />
      </div>

      <div class="pager-container" v-if="$slots.pager">
        <!-- <span class="copyright">Copyright © 2004 Adobe Systems Incorporated. All rights reserved.
©1995-2004 Macromedia, Inc. All rights reserved.</span>   -->
        <slot name="pager" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XkContainer',
  props: {
    width: {
      type: String,
    },
  },
  data() {
    return {
      heightSearch: false,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
$pad-20: 20px 20px 0 20px;
$box-shadow-col: 0 1px 5px rgba(0, 0, 0, 0.1);

.flex-col {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  //@include bg-white;

  .search {
    padding: 20px 0px 0 20px;
    @include bg-white;
    @include mb-10;
    @include box-radius;
    ::v-deep .el-form-item:not(.search-btn) {
      width: calc(25%);
      margin-right: 0;
    }
    ::v-deep .el-form-item.search-btn {
      float: right;
      // margin-right: 20px;
      // text-align: right;
    }
    ::v-deep .el-form-item__content {
      min-width: 120px;
      width: calc(100% - 20px);
    }
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
  }
  .flex-content {
    display: flex;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
  }
  .flex-wrapper {
    flex: 1;
    overflow: hidden;
    @include mb-10;
    @include box-radius;
  }
  .pager-container {
    text-align: right;
    border-radius: 0 0 5px 5px;
  }
}
// 版权信息
.copyright {
  font-size: 12px;
  color: #999;
  float: left;
  margin-top: 10px;
}
</style>
