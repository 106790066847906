/*
 * @Description: 
 * @Author: wangjieqiong
 * @Date: 2022-05-26 14:27:03
 * @LastEditors: wangjieqiong
 * @LastEditTime: 2024-09-11 15:04:29
 * @FilePath: \src\utils\request.js
 */
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

// 获取地址栏中的域名
 let getLocationHref = window.location.href
 let getDomainName=getLocationHref.split('//')[1].split('/')[0];
console.log('getDomainName',getDomainName)
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Cache-Control'] = 'no-cache'
axios.defaults.headers['Pragma'] = 'no-cache'
axios.defaults.headers['domain'] = getDomainName; 

const service = axios.create({
    baseURL: '/', // url = base url + request url
    withCredentials: true,
   //baseURL: process.env.NODE_ENV == 'development' ? '' : process.env.VUE_APP_BASE_API, // url = base url + request url
})

// 拦截请求
service.interceptors.request.use(
    config => {
      const token = store.getters.token 
      if (token) {
        config.headers.Authorization = 'Bearer ' + token 
      }
      return config
    },
    err => {
      return Promise.reject(err)
    }
)

// 拦截响应
service.interceptors.response.use(
    response => {
      // const { data } = response
      // if (!data.success&& data.msg) {
      //   Vue.prototype.$message.error({
      //     message: data.msg,
      //   })
      // }
      // return data
      // console.log('拦截响应',response);
      const { data } = response;
      data.code = 1;
      if (data.hasOwnProperty('Success') && !data.Success) {
        data.code = 0;
        // console.log('拦截响应',data.Msg);
        Vue.prototype.$message.error({
          message: data.Msg||'操作失败',
        })
        // let error = JSON.parse(data.Msg).errors;
        // let type = typeof error;
        // if(type == 'string'){
        //   Vue.prototype.$message.error({
        //     message: data.Errors.substr(error.indexOf("]") + 1),
        //   })
        // }else{
        //   let msg = Object.values(error).map(item=>item[0]);
        //   Vue.prototype.$message.error({
        //     message: msg.join('，'),
        //   })
        // }
      }
      return data;
    },
    async error => {
      if(error){
          const {response} = error;
          if(response.status >= 400 && response.status < 500){
            Vue.prototype.$message.error(`请求出错：${response.status}`)
          }else if(response.status >= 500){
            Vue.prototype.$message.error(`服务异常，请稍后重试：${response.status}`)
          }
          if(response.data.message === 'Network Error'){
            Vue.prototype.$message.error('网络异常，请稍后重试')
          }
      }
      return Promise.reject(error)
    }
)
export default service
