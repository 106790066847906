<!-- 燃气用户投诉 -->
<template>
  <XkContainer>
    <el-form :inline="true" :model="upData" ref="dateForm" class="add-form-box add-form-style mt-10" label-width="80px" label-position="left">
      <el-col :span="24">
        <el-form-item>
          <span class="place-orderInfo-title">其他业务描述</span>
        </el-form-item>
        <el-form-item prop="Content">
          <el-input type="textarea" :rows="4" placeholder="其他业务描述" v-model="upData.Content" clearable> </el-input>
        </el-form-item>
      </el-col>
      <el-form-item>
        <span class="place-orderInfo-title">近三次记录</span>
      </el-form-item>
      <div style="width:94%;margin:0 auto">
        <el-table ref="mainTable" highlight-current-row border :data="tableData" height="100%" v-loading="tableLoading">
          <el-table-column label="序号" prop="RowNum" align="center" width="80"></el-table-column>
          <el-table-column label="登记时间" prop="OrderTime"></el-table-column>
          <el-table-column label="登记人" prop="OrderPeople"></el-table-column>
          <el-table-column label="备注内容" prop="Remark"> </el-table-column>
          <el-table-column label="处理人" prop="Operator"></el-table-column>
        </el-table>
      </div>
      <br />
      <el-col :span="10">
        <el-form-item label="责任机构">
          <DropDownStationList :val.sync="upData.StationId" placeholder="请选择责任机构"></DropDownStationList>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="备注" prop="Remark">
          <el-input type="textarea" :rows="4" placeholder="备注" v-model="upData.Remark" clearable> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item align="right">
          <el-button type="primary" round @click="addInfo">保存</el-button>
        </el-form-item>
      </el-col>
    </el-form>
  </XkContainer>
</template>
<script>
import { OtherOrdersAdd, OtherOrdersThreeList } from '@/api/javaOtherOrders.js';
import DropDownStationList from '@/components/DropDown/StationList';
import { mapGetters } from 'vuex';
export default {
  name: '',
  data() {
    return {
      upData: {
        Phone: '',
        Source: 1,
      },
      tableData: [],
      tableLoading: false,
    };
  },
  components: {
    DropDownStationList,
  },
  mounted() {},
  created() {},
  computed: {
    ...mapGetters({
      gettOrderInfoData: 'gettOrderInfoData',
    }),
  },
  watch: {
    gettOrderInfoData: {
      handler(n, o) {
        this.upData.Consultant = this.gettOrderInfoData.Contact;
        this.upData.CustomerName = this.gettOrderInfoData.Name;
        this.upData.CustomerId = this.gettOrderInfoData.Id;
        this.upData.Phone = this.gettOrderInfoData.Phone;
        this.upData.CustomerNumber = this.gettOrderInfoData.Number;
        if (this.gettOrderInfoData.GasAddress == '') {
          this.upData.Address = this.gettOrderInfoData.IdentityAddress;
        } else {
          this.upData.Address = this.gettOrderInfoData.GasAddress;
        }
        this.upData.StationId = this.gettOrderInfoData.StationId;
        this.upData.StationName = this.gettOrderInfoData.StationName;
        this.upData = JSON.parse(JSON.stringify(this.upData));
        this.getTableList(this.gettOrderInfoData.Id);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getTableList(params) {
      try {
        this.tableLoading = true;
        let res = await OtherOrdersThreeList({ customerId: params });
        this.tableData = res?.Data;
        this.tableLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async addInfo(val) {
      if (Object.keys(this.gettOrderInfoData).length == 0 || !this.gettOrderInfoData.Id) {
        this.$message.error('请先保存用户信息!');
        return;
      }
      this.$refs['dateForm'].validate(async (valid) => {
        if (valid) {
          let res = await OtherOrdersAdd(this.upData);
          if (res.Success) {
            this.$message({
              message: res.Msg || '成功',
              type: 'success',
            });
            this.$store.dispatch('user/getOrderInfoData', {});
            //关闭下单页面
            setTimeout(() => {
              window.close();
            }, 1000);
          } else {
            this.$message.error(res.Msg || '失败');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>
