<template>
  <div>
    <el-input v-model="listName" placeholder="搜索" suffix-icon="el-icon-search"></el-input>
    <div class="text-btn">
      <el-link type="primary" @click="resTableList">恢复默认</el-link>
      <template v-if="type == 0">
        <el-link type="primary" @click="upList">上移</el-link>
        <el-link type="primary" @click="downList">下移</el-link>
      </template>
    </div>
    <div ref="tbody" style="max-height:500px;overflow-y: auto;margin-top:10px">
      <el-table
        ref="table"
        row-key="key"
        @selection-change="handleSelectionChange"
        highlight-current-row
        @current-change="handleCurrentChange"
        :show-header="false"
        :data="filteredData.length > 0 || listName ? filteredData : tableData"
        style="width: 100%"
        v-loading="vLoading"
      >
        <el-table-column width="55">
          <template v-slot="{ row }">
            <el-checkbox v-model="row.select" @change="updateOriginalData(row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column class="drag-btn" label="表名" prop="name"></el-table-column>
        <el-table-column class="drag-btn" label="列宽" prop="width" v-if="type == 0">
          <template v-slot="scope">
            <el-input v-model="scope.row.width" type="number" size="mini" placeholder="列宽"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import { getQueryJson, postAddOrUpdate } from '@/api/formDataConfig.js';
export default {
  name: 'FilterSetting',
  components: {},
  data() {
    return {
      listName: '',
      search: {
        name: '',
        configJson: '',
        filterJson: '',
      },
      tableData: [],
      tableDataNew: [],
      multipleSelection: [],
      rowIndex: null,
      filteredData: [],
      vLoading: false,
      setType: '',
    };
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  created() {
    this.setType = '2';
    this.search.name = this.$route.name;
    this.getTableName();
  },
  mounted() {
    this.$nextTick(() => {
      this.rowDrop();
    });
  },
  watch: {
    $route: {
      handler(n, o) {
        this.setType = '2';
        this.search.name = n.name;
        this.getTableName();
      },
      immediate: true,
      deep: true,
    },
    type: {
      handler(n, o) {
        this.setType = n;
        this.getTableName();
      },
      deep: true,
    },
    listName: {
      handler(n, o) {
        const regex = new RegExp(n, 'i'); // 'i' 表示不区分大小写
        this.filteredData = this.tableData.filter((item) => {
          return regex.test(item.name);
        });
      },
    },
  },
  methods: {
    getData() {
      return this.search;
    },
    // 初始化列表
    resTableList() {
      this.$confirm('确认初始化列表，该操作不可逆？')
        .then(async (_) => {
          let params = {
            name: this.$route.name + 'Default',
          };
          let res = await getQueryJson(params);
          if (this.type == '0') {
            this.tableData = JSON.parse(res?.Data.ConfigJson);
          } else if (this.type == '1') {
            this.tableData = JSON.parse(res?.Data.FilterJson);
          }
          this.saveTableList();
        })
        .catch((_) => {});
    },
    // 同步更改原数组中的select的变化
    updateOriginalData(updatedItem) {
      const originalItem = this.tableData.find((item) => item.key === updatedItem.key);
      if (originalItem) {
        originalItem.select = updatedItem.select;
      }
    },
    // 保存
    async saveTableList() {
      try {
        if (this.type == 0) {
          this.search.configJson = JSON.stringify(this.tableData);
          delete this.search.filterJson;
        }
        if (this.type == 1) {
          this.search.filterJson = JSON.stringify(this.tableData);
          delete this.search.configJson;
        }

        let res = await postAddOrUpdate(this.search);
        if (res?.Success) {
          this.$message.success('保存成功');
          this.setSeletTale();
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 加载
    async getTableName() {
      try {
        this.vLoading = true;
        let params = JSON.parse(JSON.stringify(this.search));
        delete params.configJson;
        delete params.filterJson;
        let res = await getQueryJson(params);
        if (res?.Success) {
          let jsonData;
          if (this.type === '0') jsonData = res?.Data?.ConfigJson;
          if (this.type === '1') jsonData = res?.Data?.FilterJson;
          // 首次加载页面
          if (this.setType == '2') this.resList(res?.Data);

          this.tableData = jsonData ? JSON.parse(jsonData) : [];
          console.log(this.tableData);
          // console.log('arry', this.tableData);
          console.log('jsonth', res?.Data?.ConfigJson);
          console.log('jsonfi', res?.Data?.FilterJson);
          this.toggleSelection();
          this.vLoading = false;
        }
      } catch (error) {
        console.log(1, error);
      }
      this.vLoading = false;
    },
    // 初次加载
    resList(data) {
      let list = data.ConfigJson ? JSON.parse(data.ConfigJson) : [];
      let frilter = data.FilterJson ? JSON.parse(data.FilterJson) : [];
      let newTable = [];
      list.forEach((item) => {
        if (item.select) newTable.push(item);
      });
      let obj = {};
      frilter.forEach((item) => {
        obj[item.key] = item.select;
      });
      this.$store.dispatch('user/actTableTh', newTable);
      this.$store.dispatch('user/actFilter', obj);
    },
    // 设置渲染列表
    setSeletTale() {
      if (!this.tableData || this.tableData.length < 1) {
        this.$store.dispatch('user/actTableTh', []);
        this.$store.dispatch('user/actFilter', {});
        return;
      }
      if (this.type == 0) {
        let newTable = [];
        this.tableData.forEach((item) => {
          if (item.select) newTable.push(item);
        });
        this.$store.dispatch('user/actTableTh', newTable);
      } else {
        let obj = {};
        this.tableData.forEach((item) => {
          obj[item.key] = item.select;
        });
        this.$store.dispatch('user/actFilter', obj);
      }
    },
    // 默认选中
    toggleSelection() {
      this.$nextTick(() => {
        this.tableData.forEach((item) => {
          if (item.select) {
            this.$refs.table.toggleRowSelection(item);
          }
        });
      });
    },
    // 下移
    downList() {
      if (this.rowIndex === null) return;
      if (this.rowIndex >= this.tableData.length - 1) {
        this.rowIndex = this.tableData.length - 1;
        return;
      }
      const currRow = this.tableData.splice(this.rowIndex, 1)[0];
      this.rowIndex++;
      this.tableData.splice(this.rowIndex, 0, currRow);
    },
    // 上移
    upList() {
      if (this.rowIndex == 0 || this.rowIndex === null) {
        return;
      }
      const currRow = this.tableData.splice(this.rowIndex, 1)[0];
      this.rowIndex--;
      this.tableData.splice(this.rowIndex, 0, currRow);
    },
    handleCurrentChange(val) {
      this.rowIndex = this.tableData.indexOf(val);
    },
    // 行拖拽
    rowDrop() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody');
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          if (_this.filteredData.length > 0 && _this.listName.length > 0) {
            _this.$message.warning('筛选条件下更改排序视为无效操作,请取消筛选后排序！');
          }
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
          _this.rowIndex = newIndex;
        },
      });
    },
  },
};
</script>

<style scoped>
.save-btn {
  position: relative;
  left: 200px;
  top: 34px;
  z-index: 999;
}
.text-btn {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.el-link {
  user-select: none;
}
</style>
