/*
 * @Description: 
 * @Author: MasterGeHong
 * @Date: 2023-01-10 10:40:09
 * @LastEditTime: 2023-01-11 11:16:32
 * @LastEditors: MasterGeHong
 */

//就近送气工
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/DeliverOperator`

export const GetNearbyOperator = (params, config = {}) => {
    return request.get(apiPrefix, { params: params, ...config})
} 