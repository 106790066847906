/*
 * @Description: 
 * @Author: MasterGeHong
 * @Date: 2022-06-14 09:10:22
 * @LastEditTime: 2023-01-11 15:17:26
 * @LastEditors: MasterGeHong
 */
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Role/`

/**
 * 菜单部分
 */
// 新增菜单
export const MenuAdd = (params, config = {}) => {
    return request.post(apiPrefix + 'MenuAdd', params, config)
}
// 修改菜单
export const MenuUpdata = (params, config = {}) => {
    return request.put(apiPrefix + 'MenuUpdate', params, config)
}
// 删除菜单
export const MenuDelete = (params, config = {}) => { 
    return request.delete(apiPrefix + 'MenuDelete', { params: params, ...config })
}
// 菜单列表
export const  MenuList = (params, config = {}) => { 
    return request.get(apiPrefix + 'MenuList', { params: params, ...config })
}
// 按钮列表
export const  ButtonList = (params, config = {}) => { 
    return request.get(apiPrefix + 'ButtonList', { params: params, ...config })
}
// 新增菜单的按钮
export const  ButtonAdd = (params, config = {}) => { 
    return request.post(apiPrefix + 'ButtonAdd', params, config)
}
// 修改菜单的按钮
export const  ButtonUpdate = (params, config = {}) => { 
    return request.put(apiPrefix + 'ButtonUpdate', params, config)
}
// 删除菜单的按钮
export const ButtonDelete = (params, config = {}) => { 
    return request.delete(apiPrefix + 'ButtonDelete', { params: params, ...config })
}

/**
 * 角色部分
 */
// 角色列表
export const  RoleList = (params, config = {}) => { 
    return request.get(apiPrefix + 'List', { params: params, ...config })
}
// 添加角色
export const  RoleAdd = (params, config = {}) => { 
    return request.post(apiPrefix + 'AddRole',  params, config )
}
// 修改角色
export const  UpDateRole = (params, config = {}) => { 
    return request.put(apiPrefix + 'UpDateRole',  params, config )
}
// 删除角色
export const  DeleteRole = (params, config = {}) => { 
    return request.delete(apiPrefix + 'DeleteRole', { params: params, ...config })
}
// 根据角色获取菜单以及按钮列表
export const  ButtonListMySelf = (params, config = {}) => { 
    return request.get(apiPrefix + 'ButtonListMySelf', { params: params, ...config })
}
// 提交 角色配置的菜单以及按钮
export const  ButtonListSave = (body,params, config = {}) => { 
    return request.post(apiPrefix + 'ButtonListSave', body, { params: params})
}
