<template>
  <el-dialog
    v-dialogDrag:{dialogDrag}="dialogDrag"
    v-dialogChange:{dialogChange}="dialogChange"
    ref="dialog__wrapper"
    custom-class="dialogHeightFull maxDialog"
    :title="title"
    :fullscreen="fullFlag"
    :visible.sync="maxdialogVisible"
    :append-to-body="true"
    :close-on-click-modal="clickModal"
    :show-close="false"
    :width="width"
    :top="top"
    v-if="maxdialogVisible"
    @closed="closeMaxDialog"
  >
    <div slot="title" class="maxDialog-header">
      <div></div>
      <div class="maxDialog-title">
        <span>{{ title }}</span>
      </div>
      <div class="maxDialog-icon">
        <i v-if="getIsfull" :class="fullFlag ? 'el-icon-copy-document' : 'el-icon-full-screen'" @click="IsFullscreen"></i>
        <i class="el-icon-close" @click="closeMaxDialog"></i>
      </div>
    </div>
    <slot name="content"></slot>
    <div class="resize"></div>
  </el-dialog>
</template>

<script>
export default {
  name: 'maxDialog',
  props: {
    clickModal: {
      type: Boolean,
      default: false,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    titleVisible: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '1200px',
    },
    height: {
      type: String,
      default: '100%',
    },
    top: {
      type: String,
      default: '30px',
    },
    title: {
      type: String,
      default: '',
    },
    isfullscreen: {
      type: Boolean,
      default: false, // 默认全屏
    },
    getIsfull: {
      type: Boolean,
      default: false, // 是否显示全屏
    },
  },
  data() {
    return {
      full: false, // 全屏
      dialogDrag: true, // 可拖拽
      dialogChange: false, // 可拉伸
    };
  },
  computed: {
    fullFlag: {
      get: function() {
        return this.full ?? this.isfullscreen;
      },
      set: function(n) {
        return this.full;
      },
    },
    maxdialogVisible: {
      //重新设置一个变量，接收父级传递的参数
      get: function() {
        return this.dialogVisible;
      },
      set: function(value) {
        this.$emit('update:dialogVisible', false);
      },
    },
  },
  mounted() {},
  methods: {
    // 全屏 切换
    IsFullscreen() {
      let dialogDom = document.querySelector('.maxDialog');
      let dialogBODY = document.querySelector('.el-dialog__body');
      if (this.isfullscreen == true) {
        this.full = this.full === null ? false : !this.full;
      } else {
        this.full = this.full === null ? true : !this.full;
        if (this.fullFlag) {
          dialogDom.style.height = `100vh`;
        } else {
          dialogDom.style.height = `auto`;
          dialogBODY.style.height = `auto`;
        }
        dialogDom.style.top = `0px`;
        dialogDom.style.left = `auto`;
      }
      // 传过来的全屏钩子函数
      this.$emit('maxFun');
    },
    // 关闭
    closeMaxDialog() {
      this.full = null;
      this.$emit('update:dialogVisible', false);
      // 传过来的关闭钩子函数
      this.$emit('closeFun');
    },
  },

  created() {
    this.full = this.isfullscreen;
  },
};
</script>

<style lang="scss" scoped="scoped">
::v-deep .el-dialog__header {
  // background-color: #f2f2f2;
  background-color: #fff;
  /* height: 48px; */
  padding: 10px 20px 10px;
}
.maxDialog-title {
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
}
.maxDialog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
}

.maxDialog .maxDialog-icon i {
  display: inline-block;
  height: 28px;
  width: 28px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 22px;
}

.maxDialog .maxDialog-icon i:hover {
  background-color: #ddd;
}

.maxDialog .maxDialog-icon i::before {
  font-size: 80%;
}

::v-deep .maxDialog.dialogHeightFull.el-dialog {
  margin-bottom: 0;
  overflow-y: hidden;
}

.el-dialog__wrapper {
  overflow: hidden !important;
}
.resize {
  position: absolute;
  right: 0;
  bottom: 0;
  content: '';
  width: 10px;
  height: 10px;
  cursor: se-resize;
}
::v-deep .dialogHeightFull.el-dialog.is-fullscreen .el-dialog__body {
  box-sizing: border-box;
  height: calc(100vh - 78px) !important;
  color: #606266;
  font-size: 14px;
  padding: 0;
  word-break: break-all;
  .el-dialog-main {
    height: calc(100vh - 80px) !important;
    display: flex;
    flex-direction: column;
  }
}
::v-deep .dialogHeightFull.el-dialog:not(.is-fullscreen) .el-dialog__body {
  padding: 0;
  box-sizing: border-box;
  max-height: calc(100vh - 78px - 30px - 30px) !important;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
  .el-dialog-main {
    max-height: calc(100vh - 78px - 30px - 30px) !important;
    display: flex;
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    .el-form {
      flex: 1;
      overflow-y: auto;
    }
  }
}
.el-form-item {
  padding: 0 20px !important;
}
</style>
