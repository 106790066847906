/**
 * @description: 混入
 * @author: wangjieqiong
 */
import Vue from "vue";
import { debounce } from "lodash";
import XkContainer from "@/components/XkContainer";
import XkPagination from "@/components/XkPagination";
import XkMaxDialog from "@/components/XkMaxDialog";
import XkExportExcelButton from "@/components/XkExportExcelButton";
import XkConfirmButton from "@/components/XkConfirmButton";
import XkDropDown from "@/components/DropDown/XkDropDown";
import { formatDate } from "@/utils/global"
import { mapGetters } from 'vuex';
import checkPermission from '@/utils/permission';

        /*
  按钮防抖动指令
      */
  Vue.directive('debounce', {
    inserted(el, binding) {
      el.addEventListener('click', () => {
        if (!el.disabled) {
          el.disabled = true
          setTimeout(() => {
            el.disabled = false
          }, binding.value || 3 * 1000)
        }
      })
    }
  }) 
const myMixin = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          // 一些通用表格宽度设置，如不用请在table单独设置 （wangjieqiong）
          tableIndexWidth: 55, //表格序号列的宽度
          headerCell:{
            height: '48px',
            background: '#f5f7fa',
            color: '#333333'
          },
          row:{
            height: '40px',
          },
          comPageSize:Number(localStorage.getItem('pageSize'))||20,//每页显示大小
        };
      },
      computed:{
        ...mapGetters(['hostConfig']),
      },
      components: {
        XkContainer,
        XkPagination,
        XkConfirmButton,
        XkExportExcelButton,
        XkDropDown,
        XkMaxDialog
      },
      methods: {
        checkPermission,
        // 全局防抖(先执行)
        $debounce:debounce((cb)=>{
          cb()
        },2000,{
          leading: true,trailing: false
        }), 
        // 重置表单数据
        resetForm(formName){
          this.$refs[formName].resetFields();
        },
        // 清空查询条件
        resetQuery(target){
          this[target] = this.$options.data()[target];
          this[target].PageSize = this.comPageSize;
        },
        // 路由后退
        goBack(){
          this.$router.go(-1);
        },
        // 时间格式处理
        formatDate(date, fmt){
          return date ? formatDate(date, fmt) : ''
        },
        // 格式化表格时间为年月日 yyyymmdd
        formatterYMD(row, column, cellValue) {
          return cellValue ? formatDate(cellValue, 'yyyy-MM-dd') : '';
        },
        // 格式化表格时间年月 yyyymm
        formatterYM(row, column, cellValue) {
          return cellValue ? formatDate(cellValue, 'yyyy-MM') : '';
        },

      },
    });
  },
};

Vue.use(myMixin);

export default myMixin;
