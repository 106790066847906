/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-06-09 10:35:55
 * @LastEditors: MasterGehong
 * @LastEditTime: 2023-06-15 14:40:56
 * @FilePath: \呼叫中心\src\main.js
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';  
import axios from 'axios'; 
Vue.prototype.$axios = axios;
import './index.scss';
import '@/assets/css/element-variables.scss';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/icon.css';
import '@/assets/css/common.scss'; 
import '@/icons'
import '@/mixin'
// 提前调用域名 
import '@/utils/getEndFunction.js'
 
import publicMethod from "@/utils/publicMethod.js"; //echarts公共方法引入
Vue.prototype.$publicMethod = publicMethod;    //定义在vue的原型

Vue.use(ElementUI);

Vue.config.productionTip = false;

// 添加lodash内部部分方法
import { cloneDeep } from "lodash";
import _ from 'lodash'


Vue.prototype.$cloneDeep = cloneDeep;
Vue.prototype.$_ = _

import getDate from '@/utils/getDate' //全局方法，日期
Vue.prototype.$ghGetTodayTime =getDate;

// 判断本地是否存在 localStorage.theme
import settings from './settings';
//document.body.className = localStorage.theme?'body-theme-'+ settings.theme.filter(v => v.value === localStorage.theme)[0].key:'body-theme-'+settings.theme[0].key;

import '@/directive'; //全局指令注册

import AMap from 'vue-amap' 
Vue.use(AMap)
AMap.initAMapApiLoader({
  key: 'a1efd8d383d666b290dfcb52c2967b2b',
  plugin: ['AMap.DistrictSearch','AMap.Autocomplete','AMap.MarkerClusterer', 'AMap.PlaceSearch','AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation','AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
