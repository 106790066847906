/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-10-18 17:16:31
 * @LastEditors: MasterGehong
 * @LastEditTime: 2023-02-03 15:25:18
 * @FilePath: \呼叫中心\src\api\sysConfig.js
 */
// 系统配置
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Config/`

// 列表
export const getConfigList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config })
} 
// 正式隐藏配置-列表
export const getConfigAllList = (params, config = {}) => {
    return request.get(apiPrefix + 'AllList', { params: params, ...config })
} 
// 修改
export const getConfigUpdata = (params, config = {}) => {
    return request.put(apiPrefix + 'Update',  params, config)
} 

// 积分配置

// 获取积分兑换配置项 /api/Config/GetPointsRedemption
export const getGetPointsRedemption = (params, config = {}) => {
    return request.get(apiPrefix + 'GetPointsRedemption',  {params:params, ...config})
} 
// 修改积分兑换配置项 /api/Config/UpdatePointsRedemption 
export const upDatePointsRedemption= (params, config = {}) => {
    return request.put(apiPrefix + 'UpdatePointsRedemption',  params, config)
} 


export const CusPointValue = (params, config = {}) => {
    return request.get("/api/Config/Get?key=CusPointValue", params, config)
}

export const UpdateCusPointValue = (params, config = {}) => {
    console.log(params)
    return request({
        url: "/api/Config/Update",
        method: "put",
        data: params
    })
}

// 更新配送配置
export const UpdateDeliverConfig = (params) => {
    return request({
        url: "/api/Config/UpdateDeliverConfig",
        method: "put",
        data: params
    })
}

// 获取配送配置
export const GetDeliverConfig = (params) => {
    return request({
        url: "/api/Config/GetDeliverConfig",
        method: "get",
        params
    })
}

// 启用/禁用 配送配置
export const EnableDeliverConfig = (params) => {
    return request({
        url: "/api/Config/EnableDeliverConfig",
        method: "get",
        params
    })
}
// 获取配置项
export const getConfig = (params) => {
    return request({
        url: "/api/Config/Get",
        method: "get",
        params
    })
}