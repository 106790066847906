<!--
 * @Description: 
 * @Author: lushunjie
 * @Date: 2023-09-11 11:13:41
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-05-30 11:21:24
 * @FilePath: \src\layout\components\BindQRcode.vue
-->
<template>
  <div class="main-code">
    <span class="tip-font top-tip">当前登录账号：{{ hostConfig.UserName }}</span>
    <div class="main-code-ewm">
      <div id="login_container"></div>
    </div>
    <div class="main-code-list"></div>
    <span class="tip-font bottom-tip">微信扫一扫，登录更便捷</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'BindQRcode',
  data() {
    return {
      type: 1,
      path: '',
    };
  },
  computed: {
    ...mapGetters(['hostConfig']),
  },
  methods: {},
  created() {},

  mounted() {
    this.path = encodeURIComponent(this.$route.path);
    let res = {
      url: 'https://authlogin.bi-tac.cn?type=1' + '&path=' + this.path,
      stata: window.location.origin,
    };

    var obj = new WxLogin({
      self_redirect: false,
      id: 'login_container',
      appid: 'wx228230fb9f7c1d3d',
      scope: 'snsapi_login',
      redirect_uri: encodeURIComponent(res.url),
      state: encodeURIComponent(res.stata),
      style: '',
      href:
        'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDMwMHB4O21hcmdpbi10b3A6NTBweDtib3JkZXI6MXB4IHNvbGlkICNlZWU7Ym94LXNpemluZzpib3JkZXItYm94O2JveC1zaGFkb3c6IDAgMnB4IDRweCByZ2JhKDAsIDAsIDAsIC4xMiksIDAgMCA2cHggcmdiYSgwLCAwLCAwLCAuMDQpO2JvcmRlci1yYWRpdXM6MTBweDt9Ci5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjQwcHg7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6bm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO3BhZGRpbmc6MH0KLmltcG93ZXJCb3ggLmluZm97ZGlzcGxheTpub25lfQppZnJhbWV7d2lkdGg6IDM0MHB4O30=',
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .main-code-ewm .loginPanel .title {
  display: none;
}

#login_container {
  text-align: center;
}

.tip-font {
  font-size: 16px;
  color: #000000;
  font-weight: 600;
}

.top-tip {
  position: absolute;
  top: 62px;
  left: 50%;
  transform: translate(-50%, 0px);
  white-space: nowrap;
}

.bottom-tip {
  position: absolute;
  bottom: 13px;
  left: 50%;
  transform: translate(-50%, 0px);
}
</style>
