/*
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2022-10-18 17:16:31
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-07-03 13:43:01
 */
import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}/Customer/`

// 燃气用户管理  

// 手动修改积分
export const getChangePointByEnterprise = (params, config = {}) => {
    return request.post(apiPrefix + 'ChangePointByEnterprise',  params, config)
}
// 列表
export const getCustomerList = (params, config = {}) => {
    return request.get(apiPrefix + 'List', { params: params, ...config })
}
// 新增
export const postCustomerAdd = (params, config = {}) => {
    return request.post(apiPrefix + 'Add', params, config)
}  
// 删除
export const postCustomerDelete = (params, config = {}) => {
    return request.delete(apiPrefix + 'Delete', { params: params, ...config })
}  
// 用户微信解绑
export const delUnbindopenid = (params, config = {}) => {
    return request.delete(apiPrefix + 'unbindopenid', { params: params, ...config })
}  
// 修改
export const postCustomerUpdata = (params, config = {}) => {
    return request.put(apiPrefix + 'Update', params, config)
}   
// 详情
export const getCustomerget = (params, config = {}) => {
    return request.get(apiPrefix + 'GetDetail', { params: params, ...config })
}  
// 读卡
export const getReadMsg = (params, config = {}) => {
    return request.get('/api/ReadMsg', { params: params, ...config })
}  
const getCustomerTypeListAsync= (params, config = {}) => {
    return request.get(`${process.env.VUE_APP_BASE_API}/DropDownList/CustomerTypeList`, { params: params, ...config })
}
// 积分详情  /api/Customer/PointDetail
export const getPointDetail = (params, config = {}) => {
    return request.get(apiPrefix + 'PointDetail', { params: params, ...config })
}  
// 根据客户Id及金额查询积分支付是否可用, Data为true时可用
export const getCustomerPointByMoney= (params, config = {}) => {
    return request.get(apiPrefix + 'QueryCustomerPointByMoney', { params: params, ...config })
}  
// /api/Customer/CustomerNewOrderRight
// 修改 用户是否可以下单
export const customerNewOrderRight = (params, config = {}) => {
    return request.put(apiPrefix + 'CustomerNewOrderRight', params, config)
}  

// 燃气用户发券
export const postNewCustomerCoupon = (params, config = {}) => {
    return request.post(apiPrefix + 'NewCustomerCoupon', params, config)
}

// 获取用户末次安检
export const getLastInspection = (params, config = {}) => {
    return request.get(apiPrefix + 'GetLastInspection', { params: params, ...config })
}
// 已删除用户
export const getDelList = (params, config = {}) => {
    return request.get(apiPrefix + 'DelList', { params: params, ...config })
}
// 恢复燃气用户
export const postRevover = (params, config = {}) => {
    return request.post(apiPrefix + 'Recover', params, config)
}
// 下单查询包含已删除用户
export const getListForOrder = (params, config = {}) => {
    return request.get(apiPrefix + 'ListForOrder',  { params: params, ...config })
}
 
// 查询所有绑定微信信息
export const getOpenIdByCustomerList = (params, config = {}) => {
    return request.get(apiPrefix + 'OpenIdByCustomerList',  { params: params, ...config })
}
// 批量解除燃气用户信息
export const postOpenIdUnbindFroCusIds = (params, config = {}) => {
    return request.post(apiPrefix + 'OpenIdUnbindFroCusIds',  params, config)
}
 
// 批量默认送气工
export const postBaseDeliverer = (params, config = {}) => {
    return request.post(apiPrefix + 'BaseDeliverer',params, config)
}
// 全部默认送气功
export const postBaseDelivererAll = (params, config = {}) => {
    return request.post(apiPrefix + 'BaseDelivererAll',params, config)
}
// 批量发放优惠券
export const postBaseGiftCoupons = (params, config = {}) => {
    return request.post(apiPrefix+'BaseGiftCoupons', params, config);
  };
// 全部发放优惠券
export const postBaseAllGiftCoupons = (params, config = {}) => {
return request.post(apiPrefix+'BaseAllGiftCoupons', params, config);
};
// 清除临时用户
export const postRemoveTemporaryDeliverer = (params, config = {}) => {
    return request.post(apiPrefix+'RemoveTemporaryDeliverer', params, config);
    };
    export const postRemoveTemporaryDelivererAll = (params, config = {}) => {
        return request.post(apiPrefix+'RemoveTemporaryDelivererAll', params, config);
        }; 