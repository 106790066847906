<!-- 燃气用户投诉 -->
<template>
  <XkContainer>
    <el-form :inline="true" :model="upData" ref="dateForm" :rules="rules" class="add-form-box add-form-style mt-10" label-width="80px" label-position="left">
      <el-col :span="8">
        <el-form-item label="投诉人" prop="CustomerName">
          <el-input maxLength="100" placeholder="投诉人" v-model="upData.CustomerName" clearable> </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="投诉电话" prop="Phone">
          <el-input maxLength="100" placeholder="投诉电话" v-model="upData.Phone" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="所属站点">
          <DropDownStationList :val.sync="upData.StationId" placeholder="请选择站点"></DropDownStationList>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="投诉地址" prop="Address">
          <el-input maxLength="100" placeholder="投诉地址" v-model="upData.Address" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="投诉标题" prop="Title">
          <el-input maxLength="100" placeholder="投诉标题" v-model="upData.Title" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="投诉内容" prop="Content">
          <el-input maxLength="100" placeholder="投诉内容" type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" v-model="upData.Content" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item align="right">
          <el-button type="primary" :loading="btnLogin" round @click="addInfo">保存</el-button>
        </el-form-item>
      </el-col>
    </el-form>
  </XkContainer>
</template>
<script>
import { postComplaintAdd } from '@/api/customerComplaint.js';
import DropDownStationList from '@/components/DropDown/StationList';
import { mapGetters } from 'vuex';
export default {
  name: '',
  data() {
    return {
      btnLogin: false, //加载下单按钮
      upData: {
        Phone: '',
      },
      alertCustome: false, //查询报修人弹窗
      getCustomerType: '',
      rules: {
        CustomerName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        Phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        Address: [{ required: true, message: '请输入投诉地址', trigger: 'blur' }],
        Title: [{ required: true, message: '请输入投诉标题', trigger: 'blur' }],
        Content: [{ required: true, message: '请输入投诉内容', trigger: 'blur' }],
      },
    };
  },
  components: {
    DropDownStationList,
  },
  mounted() {},
  created() {},
  computed: {
    ...mapGetters({
      gettOrderInfoData: 'gettOrderInfoData',
    }),
  },
  watch: {
    gettOrderInfoData: {
      handler(n, o) {
        this.upData.CustomerName = this.gettOrderInfoData.Name; //投诉人
        this.upData.CusId = this.gettOrderInfoData.Id; //投诉用户id
        this.upData.Phone = this.gettOrderInfoData.Phone; //投诉电话
        if (this.gettOrderInfoData.GasAddress == '') {
          this.upData.Address = this.gettOrderInfoData.IdentityAddress; //身份证地址
        } else {
          this.upData.Address = this.gettOrderInfoData.GasAddress; //报修地址
        }
        this.upData.StationId = this.gettOrderInfoData.StationId; //所属站点
        this.upData = JSON.parse(JSON.stringify(this.upData));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async addInfo(val) {
      if (Object.keys(this.gettOrderInfoData).length == 0 || !this.gettOrderInfoData.Id) {
        this.$message.error('请先保存用户信息!');
        return;
      }
      this.$refs['dateForm'].validate(async (valid) => {
        if (valid) {
          this.btnLogin = true;
          let res = await postComplaintAdd(this.upData);
          if (res.Success) {
            this.btnLogin = false;
            this.$message({
              message: res.Msg || '成功',
              type: 'success',
            });
            this.$store.dispatch('user/getOrderInfoData', {});
            //关闭下单页面
            setTimeout(() => {
              window.close();
            }, 1000);
          } else {
            this.$message.error(res.Msg || '失败');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>
