<!--
 * @Description: 
 * @Author: MasterGehong
 * @Date: 2024-01-22 16:16:04
 * @LastEditors: MasterGehong
 * @LastEditTime: 2024-01-24 13:16:17
 * @FilePath: \src\compPages\CustomTableSearch\index.vue
-->
<template>
  <el-dropdown trigger="click" :hide-on-click="false">
    <span class="el-dropdown-link">
      <i class="el-icon-setting" style="font-size:20px"></i>
    </span>
    <el-dropdown-menu slot="dropdown" :stretch="true" style="padding:0 20px 20px 20px;min-width:300px">
      <el-button size="mini" type="primary" round class="save-btn" @click="saveTableList">保存</el-button>
      <el-tabs v-model="type">
        <el-tab-pane label="表头设置" name="0"> </el-tab-pane>
        <el-tab-pane label="筛选设置" name="1"> </el-tab-pane>
      </el-tabs>
      <FilterSetting ref="tableData" :type="type"></FilterSetting>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import FilterSetting from './components/FilterSetting';
export default {
  name: 'CustomTableSearch',
  components: { FilterSetting },
  data() {
    return {
      type: '0',
    };
  },

  created() {},
  methods: {
    saveTableList() {
      this.$refs.tableData.saveTableList();
    },
  },
};
</script>

<style scoped>
.save-btn {
  position: relative;
  left: 200px;
  top: 34px;
  z-index: 999;
}
.text-btn {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.el-link {
  user-select: none;
}
</style>
