<!--
@description: 通用下拉框组件
@prop  {url<String>}       接口URL
@prop  {urlParams<Object>} 接口URL的参数
@prop  {placeholder<string>}  placeholder
@prop  {label<string>}    label字段
@prop  {value<string>}    value字段
-->
<template>
  <el-cascader
    ref="cascader"
    v-model="values"
    :options="options"
    :show-all-levels="false"
    @change="handleChange"
    :placeholder="placeholder"
    :props="{ checkStrictly: true, label: 'Label', children: 'Children', value: 'Id' }"
    style="width:100%"
    clearable
    filterable
    :disabled="setDisabled"
  ></el-cascader>
</template>

<script>
import { getStationList, getStationListWithNoLimit } from '@/api/common';

export default {
  name: 'DropDownStationList',
  data() {
    return {
      values: [],
      options: [],
      disabled: false,
    };
  },
  props: {
    setDisabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    roleStation: {
      type: String,
      default: 'noRole',
    },
    val: String,
    type: String,
  },
  computed: {},
  watch: {
    values: {
      handler() {
        
        console.log('11',this.values)
        if (this.$refs.cascader) {
          var children = this.$refs.cascader.getCheckedNodes();
          if (children.length > 0) {
            //判断有没有下级
            this.$refs.cascader.dropDownVisible = false; //监听值发生变化就关闭它
          }
        }
      },
      deep:true,
      immediate: true,
    },
    val: {
      handler(n) {
        console.log('11')
        if (n) {
          this.activeStation();
        } else {
          this.values = [];
        }
      },
      deep:true,
      immediate: true,
    },
  },
  created() {
    this.getOptions();
  },
  methods: {
    // 匹配站点
    activeStation() {
      this.options.some((item) => {
        if (item.Id == this.val) {
          this.values = [item.Id];
          return true;
        } else {
          item.Children?.some((c) => {
            if (c.Id == this.val) {
              this.values = [item.Id, c.Id];
              console.log(2,this.values)
              return true;
            } else {
              c.Children?.some((d) => {
                if (d.Id == this.val) {
                  this.values = [item.Id, c.Id, d.Id];
                  console.log(3,this.values)
                  return true;
                }
              });
            }
          });
        }
      });
    },
    async getOptions() {
      let params = {};
      //查询条件
      for (let i in this.urlParams) {
        if (this.urlParams[i]) {
          params[i] = this.urlParams[i];
        }
      }
      try {
        let res;
        if (this.roleStation != 'noRole') {
          res = await getStationListWithNoLimit(params);
        } else {
          res = await getStationList(params);
        }

        if (res?.Success) {
          let resData = res.Data;
          // if (this.type == 'user') {
          //   // 安检管理过滤企业
          //   let resDataArry = [];
          //   resData.forEach((item) => {
          //     if (item.Classify != 0) {
          //       resDataArry.push(item);
          //     }
          //   });
          //   this.options = resDataArry;
          // } else {
          this.options = resData;
          // }

          if (this.val) {
            this.activeStation();
          } else {
            this.values = [];
          }
        }
      } catch (error) {}
    },

    handleChange(value) {
      let val = this.$cloneDeep(value).pop();
      var children = this.$refs.cascader.getCheckedNodes();
      this.$emit('children', children); //选中当前节点值
      this.$emit('update:val', val);
      this.$emit('changeValue', val);
    },
  },
};
</script>

<style>
.el-cascader-menu__wrap {
  max-height: 204px !important;
}
</style>
