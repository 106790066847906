<!--  客户押瓶-->
<template>
  <XkContainer>
    <div class="flex-col">
      <div class="place-orderInfo-title">基础信息</div>
      <el-form :inline="true" :model="upData" :rules="rules" ref="formName" label-width="100px" class="add-form-box add-form-style mt-10">
        <el-col :span="8">
          <el-form-item label="回收站点" prop="StationId">
            <DropDownStationList :val.sync="upData.StationId" placeholder="回收站点"></DropDownStationList>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="回收人员" prop="UserId">
            <DeliveryWorker
              :url="'getCylinderRecycleUserListByStation'"
              :urlParams="operUrlParams"
              :val.sync="upData.UserId"
              @handleChange="changeUserId"
              placeholder="回收人员"
              lable="Name"
              value="Id"
            ></DeliveryWorker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="回收日期" prop="RecycleDate">
            <el-date-picker style="width:100%" class="gh-elect" value-format="yyyy-MM-dd" v-model="upData.RecycleDate" type="date" placeholder="回收日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="回收单号" :prop="cylinderRecycleNo == 1 ? 'Number' : 'noNumber'">
            <el-input maxLength="100" v-model="upData.Number" placeholder="回收单号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="支付方式" :prop="cylinderRecyclePayType == 1 ? 'PayType' : 'noPayType'">
            <el-select class="gh-elect" v-model="upData.PayType" placeholder="支付方式">
              <el-option label="现金" :value="1"> </el-option>
              <el-option label="微信" :value="2"> </el-option>
              <el-option label="支付宝" :value="3"> </el-option>
              <el-option label="月结欠款" :value="4"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="place-orderInfo-title">客户信息</div>
        </el-col>
        <el-col :span="8">
          <el-form-item label="客户名称" prop="CustomerName">
            <el-input maxLength="100" placeholder="客户名称" readonly v-model="upData.CustomerName">
              <template slot="suffix">
                <el-button type="primary" size="mini" @click="getCustomer">查询</el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="客户电话" prop="CustomerPhone">
            <el-input maxLength="100" v-model="upData.CustomerPhone" placeholder="客户电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input maxLength="100" v-model="upData.Remark" placeholder="备注"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <!-- 表格区域 -->
      <el-form :inline="true">
        <el-col :span="24">
          <el-form-item><span class="place-orderInfo-title">回收列表</span></el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" @click="alertAdd = true">添加</el-button>
            <span class="fc-blu tips">合计回收瓶 {{ getNumber }} 总价{{ upData.Money || 0 }}</span>
          </el-form-item>
        </el-col>
      </el-form>
      <div class="table-box">
        <el-table ref="mainTable" highlight-current-row border :data="tableData" height="100%" v-loading="tableLoading">
          <el-table-column label="序号" width="80" type="index" align="center"></el-table-column>
          <el-table-column label="气瓶规格" prop="CylinderSpec"></el-table-column>
          <el-table-column width="80" label="年份" prop="CylinderYear"></el-table-column>
          <el-table-column label="气瓶状态" prop="CylinderStatus">
            <template v-slot="{ row }">
              <el-select v-model="row.CylinderStatus" placeholder="请选择">
                <el-option label="合格" :value="1"> </el-option>
                <el-option label="待检验" :value="2"> </el-option>
                <el-option label="报废" :value="3"> </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="回收数量" prop="Amount">
            <template v-slot="{ row }">
              <!-- <el-input v-model="row.Amount" @input="updateTotal()" clearable></el-input> -->
              <el-input-number style="width:100%" :precision="0" v-model="row.Amount" :step="1" controls-position="right" @change="updateTotal" :min="1"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="回收单价" prop="Price">
            <template v-slot="{ row }">
              <el-input-number style="width:100%" :precision="0" v-model="row.Price" :step="1" controls-position="right" @change="updateTotal" :min="0"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="回收总价">
            <template v-slot="{ row }">
              <el-input-number style="width:100%" :precision="0" v-model="row.TotalMoney" :step="1" controls-position="right" @change="updateTotal" :min="0"></el-input-number>

              <!-- <span> {{ row.Amount * row.Price }}</span> -->
            </template>
          </el-table-column>
          <el-table-column label="操作" class-name="table-opt" fixed="right">
            <template v-slot="{ row }">
              <XkConfirmButton type="danger" @click="handleDel(row)" />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row type="flex" style="margin-bottom:29px">
        <el-col :span="12">
          <span style="padding-right: 20px;">
            实付总额: 
            <el-input :disabled="cylinderRecycleCanEditPayMoney!=='1'" size="mini" style="width:100px;text-align: left;" v-model="upData.LastMoney" @input="handleNumberInput"></el-input>
          </span> 
          <span v-if="type != 'add'">气瓶条码: {{ upData.Barcode }}</span>
        </el-col>
        <el-col :span="6" :offset="6">
          <el-button round @click="$emit('closeInfo')"> 取消 </el-button>
          <el-button type="primary" round @click="newAddInfo"> 保存 </el-button>
        </el-col>
      </el-row>
    </div>

    <!-- 商品新增弹窗 -->
    <XkMaxDialog :dialogVisible.sync="alertAdd" :title="`回收瓶信息`" :getIsfull="false" :titleVisible.sync="alertAdd" :width="'800px'">
      <template #content>
        <PlaceAnOrderSeachCustomer @addInfo="getPreInfo" @closeInfo="alertAdd = false"></PlaceAnOrderSeachCustomer>
      </template>
    </XkMaxDialog>
    <!-- 查询客户弹窗 -->
    <XkMaxDialog :dialogVisible.sync="alertCustome" :title="`查询客户`" :getIsfull="false" :titleVisible.sync="alertCustome">
      <template #content>
        <PlaceAnOrderAddCustomer @getData="getCusInfo" @closeInfo="alertCustome = false"></PlaceAnOrderAddCustomer>
      </template>
    </XkMaxDialog>
  </XkContainer>
</template>
<script>
import DropDownStationList from '@/components/DropDown/StationList';
import PlaceAnOrderSeachCustomer from './placeAnOrderAddCustomer.vue';
import PlaceAnOrderAddCustomer from '@/compPages/searchUser/index.vue'; //查询客户组件
import DeliveryWorker from '@/components/DropDown/DeliveryWorker';
import { AddOrUpdate } from '@/api/recycledBottle';
import { getConfigList } from '@/api/sysConfig';
import { mapGetters } from 'vuex';
export default {
  name: 'userManageAdd',
  data() {
    return {
      btnLogin: false, //加载下单按钮
      tableLoading: false,
      alertAdd: false, //商品新增
      alertCustome: false, //查询客户弹窗
      upData: {
        RecycleDate: '',
        CustomerName: '',
        CustomerPhone: '',
        UserId: '',
      },
      rules: {
        StationId: [{ required: true, message: '请选择', trigger: 'blur' }],
        UserId: [{ required: true, message: '请选择', trigger: 'change' }],
        RecycleDate: [{ required: true, message: '请选择', trigger: 'blur' }],
        billNum: [{ required: true, message: '请选择', trigger: 'blur' }],
        Number: [{ required: true, message: '请选择', trigger: 'blur' }],
        PayType: [{ required: true, message: '请选择', trigger: 'blur' }],
        CustomerName: [{ required: true, message: '请选择', trigger: 'change' }],
        CustomerPhone: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      tableData: [],
      getNumber: 0,
      urlParams: {
        PageIndex: 1,
        PageSize: 100,
      },

      alertCustome: false,
      cylinderRecycleNo: '',
      cylinderRecyclePayType: '',
      cylinderRecycleCanEditPayMoney:'',
    };
  },
  props: {
    propsData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      gettOrderInfoData: 'gettOrderInfoData',
    }),
    operUrlParams: function() {
      return { stationId: this.upData.StationId };
    },
  },
  watch: {
    gettOrderInfoData: {
      handler(n, o) {
        this.upData.CustomerName = this.gettOrderInfoData.Name;
        this.upData.CustomerPhone = this.gettOrderInfoData.Phone;
        this.upData.CustomerId = this.gettOrderInfoData.Id;
        this.upData.StationId = this.gettOrderInfoData.StationId;
        this.upData = JSON.parse(JSON.stringify(this.upData));
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    DeliveryWorker,
    DropDownStationList,
    PlaceAnOrderAddCustomer,
    PlaceAnOrderSeachCustomer, //查询客户
  },
  created() {
    this.configList();
    this.upData.RecycleDate = this.$ghGetTodayTime.timeStampToFormatedDateStr();
    if (this.propsData?.CustomerId) {
      let params = JSON.parse(JSON.stringify(this.propsData));
      this.tableData = params.DetailList;
      this.upData = params;
      this.updateTotal();
    }
  },
  methods: {
    // 实付
    handleNumberInput(value) {
      const regex = /^\d*\.?\d{0,2}$/;
      if (regex.test(value)) {
        return;
      }
      this.upData.LastMoney = value.replace(/[^0-9.]/g, '')
                                    .replace(/(\..*)\./g, '$1')
                                    .replace(/(\..{2}).*/g, '$1');
    },
    // 获取配置项，判断回收瓶 单号是否必填
    async configList() {
      try {
        let res = await getConfigList();
        let resData = JSON.parse(JSON.stringify(res.Data));
        resData.forEach((item) => {
          if (item.Key == 'CylinderRecycleNo') {
            this.cylinderRecycleNo = item.Value;
          }
          if (item.Key == 'CylinderRecyclePayType') {
            this.cylinderRecyclePayType = item.Value;
          }
          if(item.Key == 'CylinderRecycleCanEditPayMoney'){
            this.cylinderRecycleCanEditPayMoney = item.Value;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // 查询客户弹窗
    getCustomer() {
      this.getCustomerType = 'Manual'; //手动
      this.alertCustome = true;
    },
    // 选择回收人员
    changeUserId(params) {
      this.upData.UserId = params.Id;
    },
    getCusInfo(val) {
      if (!val.Name || !val.Id) return;
      this.upData.CustomerName = val.Name;
      this.upData.CustomerId = val.Id;
      this.upData.CustomerPhone = val.Phone;
      this.alertCustome = false;
    },
    updateTotal(val) {
      //行内数量变动时候，重新赋值数量， 并且重新结算
      let getMoney = 0;
      let getNumber = 0;
      this.tableData.forEach((item) => {
        item.TotalMoney = parseFloat(item.Amount || 0) * parseFloat(item.Price || 0);
        getMoney += item.TotalMoney;
        getNumber += parseFloat(item.Amount || 0);
      });
      this.upData.Money = getMoney;
      this.upData.LastMoney = this.type == 'edit' ? this.upData.LastMoney : this.upData.Money;
      this.getNumber = getNumber;
      this.upData = JSON.parse(JSON.stringify(this.upData));
    },
    newAddInfo() {
      this.$refs['formName'].validate((valid) => {
        this.$debounce(async () => {
          if (valid) {
            this.upData.DetailList = this.tableData; // 新增商品详情
            let res = await AddOrUpdate(this.upData);
            if (res.Success) {
              this.$message({
                message: '保存成功',
                type: 'success',
              });
              setTimeout(() => {
                this.$store.dispatch('user/getOrderInfoData', {});
                window.close();
              }, 1000);

              this.$emit('closeInfo');
            } else {
              this.$message.error('保存失败');
            }
          }
        });
      });
    },
    // 配送工信息
    getRolePs(val) {
      console.log(val);
      this.upData.OperatorId = val.Id; //配送员Id
      this.upData.Operator = val.Name; //:配送员
    },
    // 删除
    handleDel(val) {
      this.tableData.forEach((item, index) => {
        if (item.Id == val.Id) {
          this.tableData.splice(index, 1);
        }
      });
      this.updateTotal();
    },
    filterById(arr) {
      const filteredArr = [];
      const idSet = new Set();
      for (let obj of arr) {
        const id = obj.Id;
        if (!idSet.has(id)) {
          filteredArr.push(obj);
          idSet.add(id);
        }
      }
      return filteredArr;
    },
    getPreInfo(val) {
      let arr;
      if (val.length == 0) {
        this.alertAdd = false;
        return;
      }
      this.tableLoading = true;
      if (this.tableData.length == 0) {
        arr = val;
      } else {
        // 过滤数据，如果有同id 数据不再添加
        arr = this.filterById(this.tableData.concat(val));
      }
      arr.forEach((item) => {
        item.Amount = 1;
        item.RecyclePriceId = item.Id;
        item.TotalMoney = item.Price;
        item.CylinderStatus = 2;
      });
      console.log(arr);
      // 深拷贝数组
      this.tableData = JSON.parse(JSON.stringify(arr));
      this.updateTotal();
      this.alertAdd = false;
      this.tableLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btm-form {
  margin-top: 30px;
}
.flex-col {
  height: 80vh;
}
.table-box {
  flex: 1;
  padding-bottom: 20px;
}
.tips {
  margin-left: 20px;
}
::v-deep .nostyle{
  .el-input-number__decrease,.el-input-number__increase{
    display: none
  }
  &.el-input-number.is-controls-right .el-input__inner{
    padding-right: 15px;
  }
}
</style>
