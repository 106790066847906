<!--
@description: 通用下拉框组件
@prop  {url<String>}       接口URL
@prop  {urlParams<Object>} 接口URL的参数
@prop  {placeholder<string>}  placeholder
@prop  {label<string>}    label字段
@prop  {value<string>}    value字段
-->
<template>
  <el-select
    v-loading="setOperatorId"
    v-model="values"
    :multiple="multiple"
    :placeholder="placeholder"
    @change="handleChange"
    filterable
    :clearable="setClearable"
    :disabled="disabled"
    style="width:100%"
  >
    <el-option v-for="(item,index) in options" :key="item[value]+index" :label="item[lable]" :value="item[value]"> </el-option>
  </el-select>
</template>

<script>
import common from '@/api/common';
export default {
  name: 'XkDropDown',
  data() {
    return {
      values: '',
      setOperatorId: false,
      options: [],
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    multiple: {
      type: Boolean,
    },
    url: {
      type: String,
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    dataTarget: {
      type: Array,
      default() {
        return ['Data'];
      },
    },
    lable: {
      type: String,
      default: 'Name',
    },
    value: {
      type: String,
      default: 'Id',
    },
    val: {
      type: String | Number | Array,
    },
    paramsWatch: {
      type: String | Number | Object,
    },
    getDataBoolen: {
      type: Boolean,
      default: true,
    },
    setClearable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  watch: {
    val: {
      handler(nVal) {
        this.values = nVal;
      },
      deep: true,
      immediate: true,
    },
    paramsWatch: {
      handler(newVal, oldVal) {
        this.$emit('update:val', '');
        this.$emit('handleChange', '');
        this.getOptions();
      },
      deep: true,
    },
    urlParams: {
      handler(n, o) {
        this.values = '';
        this.setOperatorId = true;
        setTimeout(() => {
          this.setOperatorId = false;
        }, 500);
        this.getOptions();
      },
    },
  },
  created() {
    this.getOptions();
  },
  methods: {
    async getOptions() {
      if (!this.getDataBoolen) return;
      let params = {};
      //查询条件
      for (let i in this.urlParams) {
        if (this.urlParams[i]) {
          params[i] = this.urlParams[i];
        }
      }
      try {
        let res = await common[this.url](params);
        if (res.code == 1) {
          if (this.dataTarget.length == 1) {
            this.options = res[this.dataTarget[0]];
            this.activeFilter();
            return;
          }
          this.dataTarget.reduce((prev, next) => {
            this.options = res[prev][next];
          });
          this.activeFilter();
        }
      } catch (error) {}
    },
    // 当前默认值是否有效，有显示 没显示空
    activeFilter() {
      let params = this.options.filter((item) => item[this.value] == this.values);

      if (params.length > 0) {
        this.values = this.values;
      } else {
        this.$emit('update:val', '');
      }
    },
    handleChange(value) {
      console.log(this.values);
      this.$emit('update:val', this.values);
      this.$emit('handleChange', value);
    },
  },
};
</script>

<style></style>
