<template>
   
  <el-button type="primary" round plain icon="el-icon-upload2" @click="exportConfirm">导出</el-button>
  <!-- <el-button type="primary" @click="exportConfirm">导出Excel<i class="el-icon-download el-icon--right"></i></el-button> -->
</template>

<script>
  import {formatJson} from "@/utils/global";
  export default {
    name: 'exportBtn',
    props: {
     exportData:{
       type:Array,
       default: () => {
       	return []
       },
       required:true
     },
     total:{
       type:Number,
       default:0
     },
     tHeader:{
       type:Array,
       default: () => {
       	return []
       },
       required:true
     },
     filterVal:{
       type:Array,
       default: () => {
       	return []
       },
       required:true
     },
     filename:{
       type:String,
       default:'',
       required:true
     },
     v:{
       type:String,
       default:'',
     },
     exportLoading:{
        type:Number,
        default:0,
     }
    },
    watch: {
      exportData: {
        handler: function (newVal, oldVal) {
          if(this.exportData.length>0){
            this.exportExcel()
          }
        },
        deep: true,
        immediate:true
      },
      exportLoading:{
        handler(n,o){
          if(this.exportLoading !== 0){
            this.$notify.closeAll()
          }
        },
        deep:true,
      }
    },
    methods: {
     //表格导出
     exportConfirm(){
       let confirmTitle='确认导出全部数据？'
       if(this.total>5000){
         confirmTitle='数据量超过5000条，可能导致网页卡顿，确认导出？'
       }
       this.$confirm(confirmTitle, '提示', {
           confirmButtonText: '确定',
           cancelButtonText: '取消',
           type: 'warning'
       }).then(() => {
        if(this.total>0){
          this.$notify({
            title: '提示',
            message: '正在导出，请耐心等待。',
            duration:0
          });
        }
         this.$emit('exportFun')
       }).catch(() => {
           return;
       });
     },
     async exportExcel(){
        //获取数据
        let exportData = this.exportData;
        import('@/plugins/Export2Excel').then(excel => {
           const multiHeader = []
           const merges = [];
           const tHeader = this.tHeader//头
           const filterVal = this.filterVal//值

           const data = formatJson(filterVal, exportData)
           excel.export_json_to_excel({
             multiHeader,
             header: tHeader,
             merges,
             data,
             filename: this.filename
           })
        })
     },
    }
  }
</script>

<style scoped>

</style>
