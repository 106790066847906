<!-- 订单记录 -->
<template>
  <XkContainer id="">
    <!-- 表格-->
    <div class="table-box">
      <el-table ref="mainTable" highlight-current-row border :data="tableData" height="100%" v-loading="tableLoading">
        <el-table-column label="序号" width="55" prop="RowIndex" align="center"></el-table-column>
        <el-table-column label="下单时间" width="180" prop="DeliveryTime">
          <template v-slot="scope">
            {{ scope.row.OrderTime ? scope.row.OrderTime.split('T')[0] : '' }}
          </template>
        </el-table-column>
        <el-table-column label="订单号" prop="Number"></el-table-column>
        <el-table-column label="商品" prop="ProductDetail" show-overflow-tooltip> </el-table-column>
        <el-table-column label="总金额" prop="Money"> </el-table-column>
        <el-table-column label="送气工" prop="Operator"></el-table-column>
        <el-table-column label="备注" prop="Remark"></el-table-column>
        <el-table-column label="订单状态" prop="StatusDesc"></el-table-column>
        <el-table-column label="操作" class-name="table-opt" fixed="right">
          <template v-slot="scope">
            <el-link type="primary" size="mini" class="opt-item" @click="getDetails(scope.row)">详情</el-link>
            <el-tag v-if="scope.row.StatusDesc == '配送中'" effect="dark" size="mini" @click="reminder(scope.row)">催单</el-tag>
            <!-- <el-tag v-if="checkPermission('订单记录撤单')" effect="dark" size="mini" @click="resReminder(scope.row)">撤单</el-tag> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <template #pager>
      <XkPagination :total="total" :limit="comPageSize" :page="queryData.PageIndex" @pagination="pagination" />
    </template>
    <!-- 催单 -->
    <XkMaxDialog width="500px" :dialogVisible.sync="showReminder" :title="`催单`" :getIsfull="false" :titleVisible.sync="showReminder">
      <template #content>
        <XkReminder :id="cdOrderId" @close="showReminder = false"></XkReminder>
      </template>
    </XkMaxDialog>
    <!-- 订单详情 -->
    <XkMaxDialog :dialogVisible.sync="alertSee" :title="`订单详情`" :getIsfull="false" :titleVisible.sync="alertSee">
      <template #content>
        <OrderRecordInfo :id="detailId"></OrderRecordInfo>
      </template>
    </XkMaxDialog>
    <!-- 撤单 -->
    <XkMaxDialog :dialogVisible.sync="changeBoolen" :title="'撤单'" :width="'500px'" :getIsfull="false" :titleVisible.sync="changeBoolen">
      <template #content>
        <div class="el-dialog-main">
          <el-form :inline="true" style="padding: 20px 40px" class="add-form-box add-form-style" label-width="80px" :model="upData" ref="upDataForm">
            <el-form-item v-if="tisType == 2 && configRetrun.Value == 1">
              <span class="tis">提示：撤单后，金额将原路退回客户账户。</span>
            </el-form-item>
            <el-form-item label="撤单原因" prop="QuashReason" :rules="[{ required: true, message: '请填写原因', trigger: 'blur' }]">
              <el-input maxLength="100" type="textarea" border :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请填写原因" v-model="upData.QuashReason"> </el-input>
            </el-form-item>
          </el-form>
          <div class="form-btn">
            <el-button type="primary" round @click="addInfo('upDataForm')">保存</el-button>
            <el-button
              round
              @click="
                changeBoolen = false;
                upData.QuashReason = '';
              "
              >取消</el-button
            >
          </div>
        </div>
      </template>
    </XkMaxDialog>
  </XkContainer>
</template>
<script>
import OrderRecordInfo from '@/views/orderManagement/components/seeInfo.vue';
import XkReminder from '@/compPages/XkReminder';
import { getOrderHistory, QuashOrder } from '@/api/order';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  name: 'orderRecord',
  data() {
    return {
      tableLoading: false,
      alertSee: false, //详情菜单
      alertCustome: false, //查询客户弹窗
      showReminder: false, //催单
      changeBoolen: false, //撤单
      tableData: [],
      detailId: '',
      cdOrderId: '', //催单id
      total: 0,
      queryData: {
        PageIndex: 1,
      },
      upData: {},
    };
  },
  computed: {
    ...mapGetters({
      gettOrderInfoData: 'gettOrderInfoData',
    }),
  },
  created() {
    this.queryData.PageSize = this.comPageSize;
  },
  watch: {
    gettOrderInfoData: {
      handler(n, o) {
        // 手机号监听
        //if(Object.assign([], this.gettOrderInfoData.Phone).length !=11)return
        console.log('监听客户信息', this.gettOrderInfoData);
        if (!this.gettOrderInfoData.Id) return;
        if (n.Id == o.Id) return;
        this.getPageList();
      },
      deep: true,
    },
  },
  methods: {
    // 撤单保存数据
    addInfo(params) {
      this.$refs[params].validate(async (valid) => {
        if (valid) {
          let upParams = {
            id: this.upData.Id,
            QuashReason: this.upData.QuashReason,
          };
          let res = await QuashOrder(upParams);
          if (res.Success) {
            this.$message({
              message: '保存成功',
              type: 'success',
            });
            this.getPageList();
            this.$refs[params].resetFields();
            this.changeBoolen = false;
            this.$store.commit('user/setMenusData', Math.random());
          } else {
            this.$message({
              message: '撤单失败',
              type: 'warning',
            });
          }
          this.alertAdd = false;
        }
      });
    },
    // 催单
    reminder(row) {
      this.cdOrderId = row.Id;
      this.showReminder = true;
    },
    // 翻页处理
    pagination(params) {
      console.log(params);
      this.queryData.PageSize = params.comPageSize;
      this.queryData.PageIndex = params.pageCurrent;
      this.getPageList();
    },
    // 详情查看
    getDetails(value) {
      this.alertSee = true;
      this.detailId = value.Id;
    },
    async getPageList() {
      this.queryData.CusId = this.gettOrderInfoData.Id;
      if (!this.gettOrderInfoData.Id) return;
      let res = await getOrderHistory(this.queryData);
      if (res?.Success) {
        this.tableData = res.Data.Data;
        this.total = res.Data.Count;
      } else {
        console.log(res);
      }
    },
  },
  components: {
    OrderRecordInfo,
    XkReminder,
  },
};
</script>
<style lang="scss" scoped>
.table-box {
  height: calc(100vh - 140px);
}
</style>
